#scheduling {
  max-width: 1040px !important;
}

#scheduling .react-calendar {
  width: 400px;
  background-color: #f2f4f7;
}

#scheduling .ant-picker {
  border-radius: 8px;
  box-shadow: 0px 0px 6px 0px lightgrey;
  border: 0px;
}

#scheduling .ant-picker-input > input {
  font-family: 'Aller-Regular';
  color: #b3b1b1;
  font-size: 1.4rem;
}

#scheduling .modal-content {
  border-radius: 20px;
}

#scheduling .modal-body {
  padding: 20px 40px;
}

@media (max-width: 1100px) {
  #scheduling {
    max-width: 800px !important;
  }
}

@media (max-width: 880px) {
  #scheduling {
    max-width: 90% !important;
  }
}

@media (max-width: 585px) {
  #scheduling .react-calendar {
    width: 100%;
  }

  #scheduling .modal-body {
    padding: 20px;
  }
}

@media (max-width: 575px) {
  #scheduling {
    max-width: 100% !important;
  }
}
