#skeleton-video > span > span {
  display: block;
  margin: 0 auto;
  width: 800px;
  height: 460px;
  border: 0;
}

@media (max-width: 1040px) {
  #skeleton-video > span > span {
    width: 600px;
    height: 346px;
  }
}

@media (max-width: 640px) {
  #skeleton-video > span > span {
    width: 400px;
    height: 230px;
  }
}

@media (max-width: 375px) {
  #skeleton-video > span > span {
    width: 340px;
    height: 202px;
  }
}

@media (max-width: 350px) {
  #skeleton-video > span > span {
    width: 300px;
  }
}
