#playlistOfTheMonth .react-calendar__navigation__prev-button {
  display: none;
}

#playlistOfTheMonth .react-calendar__navigation__next-button {
  display: none;
}

#playlistOfTheMonth .react-calendar__navigation__label__labelText--from {
  color: #5c5c5c;
  font-family: 'Aller-Regular';
  font-size: 1.2rem;
}
